/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:6e70ad59-19ca-4398-9ab5-b8861c049dce",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_ETH6MhYKH",
    "aws_user_pools_web_client_id": "7c743054ibqire1mfi66khbkhe",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://wex7y6ddhbe5pii45n5qloqfwm.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://pqn61kqxff.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "sponsor",
            "endpoint": "https://ugf614xptf.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "student",
            "endpoint": "https://2yg1yedvc2.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "cbc-videos160938-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
